.site-title::before {
	display: inline-block;
	content: "";
	background-image: url('/assets/uploads/logo.png');
	background-size: contain;
	width: 28px; /* Adjust the width and height as needed */
	height: 28px;
	margin-right: 10px; /* Add spacing between the logo and other header content */
}

.video_wrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.video_wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.grid-card {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	
	@media (max-width: 767px) {
		grid-template-columns: 1fr; /* Change to one column on mobile devices */
	}
}

.card {
	background: var(--minima-table-zebra-color);
	filter: drop-shadow(4px 10px 10px #00000048);
	border-radius: 20px;
	overflow: hidden;
}

.card p {
	padding-top: 1rem;
	padding-inline: 1rem;
}

.card h3 {
	padding-left: 1rem;
}

.card img {
	max-width: 50%;
	padding: 2rem; 
}

.card-image {
	text-align: center;
}

.carousel {
	border-radius: 20px;
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */  
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

input[type=email], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */  
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}
